.App {
  margin: auto;
  text-align: center;
  width: 100%;
  background-color: #f8f9fa!important;
}

html, body {
  height: 100%
}
body {
  display: flex;
  align-items: stretch;
}

#root {
  width: 100%;
  color: black;
  background: white;
  /* background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
}

html, body, #app, #app>div {
  height: 100%
}

/* #root{
  background-color: #e9e9e9;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
} */
img.services {
  width: 100%; 
  max-width: 140px;
}

.avatarWrapper{
  margin: 30px;
}
img.roundImg{
  border-radius: 50%;
  margin-bottom: 15px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.logo{
  max-width : 100px;
  max-height : 100px;
  padding-top: 20px;
}

.ml-auto{
  margin-left: auto!important;
  margin-right: auto!important;
}

.navbar{
  display: inherit;
}

.App-header {
  background-color : inherit;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.name {
  font-size: 100px;
}

.circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  line-height: 145px;
  text-align: center;
  background: none;
  border-style: solid;
  border-width: 5px;
  border-color: white;
  display: inline-block;
}
#div_top_hypers {
  background-color: none;
}
ul {
  padding-inline-start: 0px;
}

#ul_top_hypers li{
  display: inline;
}

.wrapper {
  position: relative;
  overflow: hidden;
  width : 7em;
  height : 7em;
}

.container-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items:baseline;
  align-content: center;
}

.experience{
  margin-top: 60px;
}

.projects{
  margin-top: 60px;
}
.container-flex-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly ;
}

#slide {
  position: absolute;
  left: -100px;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slide 0.5s forwards;
  animation-delay: 0.5s;
}

@-webkit-keyframes slide {
  100% { left: 0; }
}

@keyframes slide {
  100% { left: 0; }
}

h1{
  color: inherit;
}

h3{
  /* color: white; */
}

.grow { 
  transition: all .2s ease-in-out;
  display : inline-block;
}
.grow:hover { 
  /* transform: scale(1.1);  */
  /* cursor: pointer; */
}

.services {
  max-width : 5em;
  max-height : 7em;
  /* position: absolute; */
  /* left: -200px; */
  /* width: 100px;
  height: 100px; */
  /* background: blue; */
  /* -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s; */
}

.title{
  color: inherit;
  margin-bottom: 20px;
}

.skillTitle{
  color: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 800px) {
  .App {
    /* background-color: pink; */
    width : 90%;
  }
}

.socialMediaSpacing {
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}

a.links{
  text-decoration: none;
}

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
  }
  @keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }